import React, { useEffect } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

const PreviewPdfUrl = (props) => {
  const { showDocument, setShowDocument, document } = props;

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const onClose = () => {
    setShowDocument(false);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={showDocument}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle className="text-primary">
        <Typography className="text-uppercase">
          {/* <AttachFileIcon className="mb-2 mr-2 text-dark" /> */}
          {document?.name}
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>

      <DialogContent dividers style={{ height: "100vh" }}>
        <div>
          <iframe
            src={`${document.url}`}
            frameBorder="0"
            style={{ width: "100%", height: "100vh" }}
          ></iframe>
          {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
            <Viewer
              fileUrl={`${document.url}`}
              plugins={[defaultLayoutPluginInstance]}
            /> 
          </Worker>*/}
        </div>
      </DialogContent>
      <DialogActions className="py-3"></DialogActions>
      {/* <DialogActions className="d-flex justify-content-center py-4"></DialogActions> */}
    </Dialog>
  );
};

export default PreviewPdfUrl;
