import {
  IconButton,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  Box,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
  TextField,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Menu,
  MenuItem,
  ListItem,
  ListItemAvatar,
  Checkbox,
  ListItemText,
  Badge,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import axios from "axios";
import moment from "moment";

import { showError } from "../../../toastify";
import ReactPaginate from "react-paginate";
import PreviewReplacementStaffDialog from "./PreviewReplacementStaffDialog";
import SearchBox from "../../common/components/SearchBox";
import VerifiedIcon from "@mui/icons-material/Verified";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

import { unselectReplacementStaff } from "../../../../store/maternity-leave/actions";

const ReplacementMatches = (props) => {
  const { user, request, showDialog, setShowDialog, unselectReplacementStaff } =
    props;

  const [loading, setLoading] = useState(true);
  const [replacementMatches, setReplacementMatches] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loader, setLoader] = useState(false);

  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );

  const getReplacementMatches = async (query) => {
    try {
      setLoading(true);

      setLoader(true);

      const { data } = await axios.get(
        `/api/maternity-leave/replacement-matches/${request.positionId}/${
          request.districtId
        }?${new URLSearchParams(query).toString()}`
      );

      setReplacementMatches(data.records);

      setTotalRecords(data.totalRecords);

      setLoader(false);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setLoader(false);
    }
  };

  const onClose = () => {
    setShowDialog(false);
  };

  // useEffect(() => {
  //   if (!schoolPositions.length)
  //     getSchoolPositions("SCHOOL", user.selectedEntity.id);
  // }, []);

  useEffect(() => {
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
    };
    getReplacementMatches(query);
  }, [user.selectedEntity, user.selectedAcademicYear]);

  const onSearch = (term) => {
    setSearchTerm(term);

    if (term === "") {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        searchTerm: _searchTerm,
        page: _currentPage,
        size: itemPerPage,
      };
      getReplacementMatches(query);
    }
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);

    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };

    getReplacementMatches(query);
  };

  const [showAddReplacementStaffDialog, setShowAddReplacementStaffDialog] =
    useState(false);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="text-primary pb-0 mb-0 text-uppercase border">
          <span className="d-flex align-items-center mb-2">
            <Typography className="d-flex align-items-center text-primary">
              <span className="text-dark mr-1">
                {request.positionName} -{" "}
                <strong>({request.qualificationLevelName})</strong>
              </span>
            </Typography>
          </span>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="px-0">
          <div className="px-4 pt-2">
            <div className="border rounded">
              <AppBar position="static" elevation={0} className="app-bar w-100">
                <Toolbar
                  style={{
                    minHeight: "0px",
                    borderBottom: "3px solid #e9ecef",
                  }}
                  className=" my-0 py-1 bg-light"
                >
                  <Typography
                    variant="p"
                    noWrap
                    component="div"
                    className="d-flex  ilign-items-center text-uppercase text-primary font-weight-bold"
                  >
                    <span className="text-dark">
                      ({replacementMatches.length}) Replacing Staff
                    </span>
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />

                  <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                    <SearchBox
                      onSearch={onSearch}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          const query = {
                            searchTerm: searchTerm,
                            page: currentPage,
                            size: itemPerPage,
                          };

                          getReplacementMatches(
                            query,
                            setTotalRecords,
                            setLoader
                          );
                        }
                      }}
                      placeholder="Search…"
                    />
                  </Box>
                </Toolbar>
              </AppBar>
              <div
                style={{
                  backgroundColor: "#f3f4f6",
                  borderRadius: "0.5rem",
                  boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                  MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                  height: "65vh",
                  overflowY: "auto",
                }}
                className="px-3"
              >
                {!!replacementMatches.length && (
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr style={{ backgroundColor: "#f0f2f5" }}>
                        <th scope="col">#</th>
                        <th scope="col" className="text-left">
                          Staff Id
                        </th>

                        <th scope="col" className="text-left">
                          Staff Names
                        </th>

                        <th scope="col" className="text-left">
                          Email
                        </th>

                        <th scope="col" className="text-left">
                          Phone
                        </th>

                        <th scope="col" className="text-left">
                          Registered By
                        </th>

                        <th scope="col" className="text-left">
                          Staff Residence
                        </th>

                        {/* <th scope="col" className="text-left">
                          Position
                        </th> */}

                        <th scope="col" className="text-left">
                          Registered On
                        </th>

                        <th scope="col" className="text-right">
                          Status
                        </th>

                        <th scope="col" className="text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {replacementMatches.map((replacementsMatche) => (
                        <ReplacementItemCard
                          key={replacementsMatche.no}
                          replacementsMatche={replacementsMatche}
                          request={request}
                          user={user}
                          loading={loading}
                          setShowDialog={setShowDialog}
                          unselectReplacementStaff={unselectReplacementStaff}
                        />
                      ))}
                    </tbody>
                  </table>
                )}

                {!replacementMatches?.length && (
                  <div className="jumbotron jumbotron-fluid text-center">
                    <div className="container py-5 ">
                      <p className="lead">
                        {loading ? "Fetching data..." : "No request made"}{" "}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ overflow: "hidden" }}
              >
                <>
                  <div>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={
                        <>
                          <span className="pr-1 d-none d-md-inline">Next</span>
                          <i className="fas fa-angle-double-right"></i>
                        </>
                      }
                      previousLabel={
                        <>
                          <i className="fas fa-angle-double-left"></i>
                          <span className="pl-1  d-none d-md-inline">
                            Previous
                          </span>
                        </>
                      }
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      pageCount={Math.ceil(totalRecords / itemPerPage)}
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
                      previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
                      nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
                      activeLinkClassName="active"
                    />
                  </div>

                  {!!replacementMatches.length && (
                    <select
                      className="custom-select custom-select-sm mr-3 ml-2"
                      style={{ width: "100px" }}
                      value={itemPerPage}
                      onChange={(event) => {
                        const itemPerPage = +event.target.value;
                        window.localStorage.setItem(
                          "ITEM_PER_PAGE",
                          itemPerPage
                        );
                        setItemPerPage(itemPerPage);
                        const query = {
                          searchTerm: searchTerm,
                          page: currentPage,
                          size: itemPerPage,
                        };
                        getReplacementMatches(
                          query,
                          setTotalRecords,
                          setLoader
                        );
                      }}
                    >
                      {[10, 50, 100, 150].map((item, index) => (
                        <option key={index}>{item}</option>
                      ))}
                    </select>
                  )}
                </>
              </div>
            </div>
          </div>

          {loader && (
            <CircularProgress
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                color: "rgb(7, 142, 206)",
                position: "absolute",
                top: "42%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
              size={40}
              thickness={4}
            />
          )}
        </DialogContent>
        <DialogActions className=" d-flex justify-content-center py-4"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return {
    user,
    loading,
  };
};
export default connect(mapStateToProps, { unselectReplacementStaff })(
  ReplacementMatches
);

const ReplacementItemCard = (props) => {
  const {
    replacementsMatche,
    user,
    request,
    setShowDialog,
    unselectReplacementStaff,
  } = props;

  const [showPreviewProfileDialog, setShowPreviewProfileDialog] =
    useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showUnselect, setShowUnselect] = useState(false);

  return (
    <>
      <tr>
        <th scope="row" className="text-left">
          {replacementsMatche.no}
        </th>

        <td className="text-left">{replacementsMatche.userId}</td>

        <td className="text-left text-uppercase font-weight-bold">
          {replacementsMatche.lastName} {replacementsMatche.firstName}
        </td>

        <td className="text-left">{replacementsMatche.email}</td>

        <td className="text-left">{replacementsMatche.phoneNumber}</td>

        <td className="text-left">
          <div>
            {replacementsMatche.createdBy} <br />
            <span className="text-primary font-weight-bold">
              {replacementsMatche.schoolName}{" "}
              <i className="fas fa-chevron-right"></i>{" "}
              {replacementsMatche.sectorName} /{" "}
              {replacementsMatche.districtName}
            </span>
          </div>
        </td>

        {/* <td className="text-left">
          <span>-</span>
        </td> */}

        <td className="text-left">{replacementsMatche.positionName}</td>

        <td className="text-left">
          {moment(replacementsMatche.createdOn).format("lll")}
        </td>

        <td
          className={`
            text-right 
            ${
              [2, 3].includes(replacementsMatche.statusId)
                ? "font-weight-bold text-light"
                : ""
            }
          `}
          style={{
            backgroundColor: `${
              replacementsMatche.statusId === 2 && !replacementsMatche.selected
                ? "#3b35dc"
                : replacementsMatche.statusId === 2 &&
                  !!replacementsMatche.selected
                ? "#28a745"
                : replacementsMatche.statusId === 3
                ? "#dc3545"
                : "none"
            }`,
          }}
        >
          {!!replacementsMatche.selected
            ? "Selected"
            : replacementsMatche.statusName}

          {replacementsMatche.statusId !== 1 && (
            <small>
              <br /> {moment(replacementsMatche.statusOn).format("lll")}
            </small>
          )}
        </td>

        <td className="text-right">
          {/* {replacementsMatche.selected && (
            <Tooltip title="Selected" className="mr-2">
              <VerifiedIcon className="text-success" />
            </Tooltip>
          )} */}

          <IconButton size="small" onClick={handleOpenMenu}>
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setShowPreviewProfileDialog(true);
                }}
                className="text-primary font-weight-bolder"
              >
                <span className="material-icons mr-1">preview</span>
                Preview
              </MenuItem>
              <Divider className="my-1" />
            </span>

            {replacementsMatche.statusId === 2 &&
              !!replacementsMatche.selected &&
              ["REB", "RTB"].includes(user.selectedEntity.role) && (
                <span>
                  <MenuItem
                    onClick={(e) => {
                      handleCloseMenu();
                      setShowUnselect(true);
                    }}
                    className="text-warning"
                  >
                    <span className="material-icons mr-1">clear</span>
                    Unselect
                  </MenuItem>
                </span>
              )}
          </Menu>
        </td>
      </tr>

      {showPreviewProfileDialog && (
        <PreviewReplacementStaffDialog
          showDialog={showPreviewProfileDialog}
          setShowDialog={setShowPreviewProfileDialog}
          replacement={replacementsMatche}
          request={request}
          closePalentDialog={() => setShowDialog(false)}
        />
      )}

      {showUnselect && (
        <ConfirmationDialog
          confirmationDialog={showUnselect}
          message={
            <>
              <p className="mt-2 mb-0">
                Are you sure you want to{" "}
                <strong className={`text-danger`}>Unselect</strong> this staff?
              </p>
            </>
          }
          setConfirmationDialog={setShowUnselect}
          onYes={() => {
            unselectReplacementStaff(replacementsMatche.id, () => {
              setShowUnselect(false);
              setShowDialog(false);
            });
          }}
        />
      )}
    </>
  );
};
