import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  IconButton,
  Badge,
  Divider,
  Tooltip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Autocomplete,
  TextField,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";

import { CardContent, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  getMaternityLeaveReplacements,
  deleteReplacementStaff,
  getPositionsOfReplacement,
  unselectReplacementStaff,
} from "../../../../store/maternity-leave/actions";
import ReactPaginate from "react-paginate";
import moment from "moment";
import PreviewReplacementStaffDialog from "../components/PreviewReplacementStaffDialog";
import AddOrUpdateReplacementStaffDialog from "../components/AddOrUpdateReplacementStaffDialog";

import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import VerifiedIcon from "@mui/icons-material/Verified";
import axios from "axios";
import { CSVLink } from "react-csv";

const Replacements = (props) => {
  useEffect(() => {
    document.title = "TMIS | Maternity Leave - In Progress";
  }, []);

  const {
    user,
    loading,
    maternityLeaveReplacements,
    getMaternityLeaveReplacements,
    deleteReplacementStaff,
    getPositionsOfReplacement,
    positionsOfReplacement,
    unselectReplacementStaff,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecods, setTotalRecods] = useState(0);
  const [loader, setLoader] = useState(false);
  const [filterStatus, setFilterStatus] = useState({
    id: "",
    name: "All",
  });

  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );

  useEffect(() => {
    if (!positionsOfReplacement.length) getPositionsOfReplacement();
  }, []);

  useEffect(() => {
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
      statusId: filterStatus.id,
    };
    getMaternityLeaveReplacements(query, setTotalRecods, setLoader);
  }, [user.selectedEntity, user.selectedAcademicYear, filterStatus]);

  const onSearch = (term) => {
    setSearchTerm(term);

    if (term === "") {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        searchTerm: _searchTerm,
        page: _currentPage,
        size: itemPerPage,
        statusId: filterStatus.id,
      };
      getMaternityLeaveReplacements(query, setTotalRecods, setLoader);
    }
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);

    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
      statusId: filterStatus.id,
    };

    getMaternityLeaveReplacements(query, setTotalRecods, setLoader);
  };

  const [showAddReplacementStaffDialog, setShowAddReplacementStaffDialog] =
    useState(false);

  return (
    <>
      <div className="px-4 pt-2">
        <div className="border rounded">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar
              style={{
                minHeight: "0px",
                borderBottom: "3px solid #e9ecef",
              }}
              className=" my-0 py-1 bg-light"
            >
              <Typography
                variant="p"
                noWrap
                component="div"
                className="d-flex  ilign-items-center text-uppercase text-primary font-weight-bold"
              >
                <span className="text-dark">
                  ({maternityLeaveReplacements.length}) Replacing Staff
                </span>
                <CSVLink
                  data={maternityLeaveReplacements}
                  filename={`Replacing Staff - ${filterStatus?.name}.csv`}
                  className="hidden"
                  id="downloadBTN"
                  target="_blank"
                />

                <Tooltip title="Download">
                  <span className="ml-2">
                    <IconButton
                      size="small"
                      disabled={loading || !maternityLeaveReplacements.length}
                      className=" mr-1"
                      color="primary"
                      onClick={() =>
                        document.getElementById("downloadBTN").click()
                      }
                    >
                      <span className="material-icons">file_download</span>
                    </IconButton>
                  </span>
                </Tooltip>
                {["HEAD_TEACHER"].includes(user?.selectedEntity?.role) && (
                  <Button
                    className="ml-2"
                    disabled={loading}
                    onClick={() => {
                      setShowAddReplacementStaffDialog(true);
                    }}
                    variant="contained"
                    size="small"
                    disableElevation
                    color="primary"
                    style={{
                      borderRadius: "8px",
                      height: "32px",
                      alignItems: "center",
                      fontSize: ".75rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "initial",
                    }}
                  >
                    <span className="material-icons">add</span>
                    Add New
                  </Button>
                )}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <div>
                <Autocomplete
                  style={{
                    width: "200px",
                  }}
                  className="mr-2"
                  size="small"
                  defaultValue={null}
                  value={filterStatus}
                  options={[
                    { id: "", name: "All" },
                    { id: 1, name: "Pending for approval" },
                    { id: 2, name: "Approved" },
                    { id: 4, name: "Selected" },
                    { id: 3, name: "Rejected" },
                  ]}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="bank"
                      label="Filter By"
                      placeholder="Filter By"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                  onChange={(event, status) => {
                    setFilterStatus(status || { id: "", name: "All" });
                  }}
                />
              </div>
              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <SearchBox
                  onSearch={onSearch}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      const query = {
                        searchTerm: searchTerm,
                        page: currentPage,
                        size: itemPerPage,
                        statusId: filterStatus.id,
                      };

                      getMaternityLeaveReplacements(
                        query,
                        setTotalRecods,
                        setLoader
                      );
                    }
                  }}
                  placeholder="Search…"
                />
              </Box>
            </Toolbar>
          </AppBar>
          <div
            style={{
              backgroundColor: "#f3f4f6",
              borderRadius: "0.5rem",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
              height: "60vh",
              overflowY: "auto",
            }}
            className="px-3"
          >
            {!!maternityLeaveReplacements.length && (
              <table className="table table-striped table-hover">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col">#</th>
                    <th scope="col" className="text-left">
                      Staff Id
                    </th>

                    <th scope="col" className="text-left">
                      Staff Names
                    </th>

                    {/* <th scope="col" className="text-left">
                      Email
                    </th>

                    <th scope="col" className="text-left">
                      Phone
                    </th> */}

                    <th scope="col" className="text-center">
                      #Positions
                    </th>

                    <th scope="col" className="text-left">
                      Registered On
                    </th>

                    <th scope="col" className="text-left">
                      Registered By
                    </th>

                    <th scope="col" className="text-right">
                      Status
                    </th>

                    {/* <th scope="col" className="text-center">
                      Status On
                    </th> */}

                    {/* <th scope="col" className="text-center">
                      Status By
                    </th> */}

                    <th scope="col" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {maternityLeaveReplacements.map((replacement) => (
                    <ReplacementItemCard
                      key={replacement.id}
                      replacement={replacement}
                      user={user}
                      loading={loading}
                      deleteReplacementStaff={deleteReplacementStaff}
                      unselectReplacementStaff={unselectReplacementStaff}
                    />
                  ))}
                </tbody>
              </table>
            )}

            {!maternityLeaveReplacements?.length && (
              <div className="jumbotron jumbotron-fluid text-center">
                <div className="container py-5 ">
                  <p className="lead">
                    {loading ? "Fetching data..." : "No request made"}{" "}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            className="d-flex justify-content-center  align-items-center"
            style={{ overflow: "hidden" }}
          >
            <>
              <div className="mt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(totalRecods / itemPerPage)}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
                  previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
                  nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
                  activeLinkClassName="active"
                />
              </div>

              {!!maternityLeaveReplacements.length && (
                <select
                  className="custom-select custom-select-sm mr-3 ml-2"
                  style={{ width: "100px" }}
                  value={itemPerPage}
                  onChange={(event) => {
                    const itemPerPage = +event.target.value;
                    window.localStorage.setItem("ITEM_PER_PAGE", itemPerPage);
                    setItemPerPage(itemPerPage);
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                      statusId: filterStatus.id,
                    };
                    getMaternityLeaveReplacements(
                      query,
                      setTotalRecods,
                      setLoader
                    );
                  }}
                >
                  {[10, 50, 100, 150, 200, 500].map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              )}
            </>
          </div>
        </div>
      </div>

      {loader && (
        <CircularProgress
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "rgb(7, 142, 206)",
            position: "absolute",
            top: "42%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
          size={40}
          thickness={4}
        />
      )}

      {showAddReplacementStaffDialog && (
        <AddOrUpdateReplacementStaffDialog
          showDialog={showAddReplacementStaffDialog}
          setShowDialog={setShowAddReplacementStaffDialog}
          replacement={{}}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  maternityLeaveReplacements,
  positionsOfReplacement,
}) => {
  return { user, loading, maternityLeaveReplacements, positionsOfReplacement };
};

export default connect(mapStateToProps, {
  getMaternityLeaveReplacements,
  deleteReplacementStaff,
  getPositionsOfReplacement,
  unselectReplacementStaff,
})(Replacements);

const ReplacementItemCard = (props) => {
  const {
    replacement,
    user,
    deleteReplacementStaff,
    unselectReplacementStaff,
  } = props;

  const [showPreviewProfileDialog, setShowPreviewProfileDialog] =
    useState(false);

  const [showStaffPositionsDialog, setShowStaffPositionsDialog] =
    useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showConfirmRemove, setShowConfirmRemove] = useState(false);

  const [showUnselect, setShowUnselect] = useState(false);

  return (
    <>
      <tr>
        <th scope="row" className="text-left">
          {replacement.no}
        </th>

        <td className="text-left">{replacement.userId}</td>

        <td className="text-left">
          <button
            onClick={() => setShowPreviewProfileDialog(true)}
            className="btn btn-link btn-sm px-0 py-0 text-uppercase text-left text-underline font-weight-bold"
          >
            {replacement.lastName} {replacement.firstName}
          </button>
          <div>
            <span className="text-dark font-weight-bold">
              <i className="fas fa-chevron-right"></i> {replacement.schoolName}{" "}
              <i className="fas fa-chevron-right"></i> {replacement.sectorName}{" "}
              / {replacement.districtName}
            </span>{" "}
            <br />
            <i className="fas fa-chevron-right"></i>{" "}
            <a href={`mailto:${replacement.email}`}>{replacement.email}</a>
            <br />
            <i className="fas fa-chevron-right"></i>{" "}
            <a href={`tel:+25${replacement.phoneNumber}`}>
              {replacement.phoneNumber}
            </a>
          </div>
        </td>

        {/* <td className="text-left">{replacement.email}</td> */}

        {/* <td className="text-left">{replacement.phoneNumber}</td> */}

        <td className="text-center" style={{ width: "120px" }}>
          <button
            className={`btn btn-link btn-sm text-${
              replacement.numberOfPositions > 0 ? "primary" : "danger"
            }`}
            onClick={() => setShowStaffPositionsDialog(true)}
          >
            {replacement.numberOfPositions} Position
            {replacement.numberOfPositions > 1 ? "s" : ""}
          </button>
        </td>

        <td className="text-left">
          {moment(replacement.createdOn).format("lll")}
        </td>

        <td className="text-left">
          <div>
            {replacement.createdBy} <br />
            {/* <span className="text-primary font-weight-bold">
              {replacement.schoolName} <i className="fas fa-chevron-right"></i>{" "}
              {replacement.sectorName} / {replacement.districtName}
            </span> */}
          </div>
        </td>

        <td
          className={`
            text-right 
            ${
              [2, 3].includes(replacement.statusId)
                ? "font-weight-bold text-light"
                : ""
            }
          `}
          style={{
            backgroundColor: `${
              replacement.statusId === 2 && !replacement.selected
                ? "#3b35dc"
                : replacement.statusId === 2 && !!replacement.selected
                ? "#28a745"
                : replacement.statusId === 3
                ? "#dc3545"
                : "none"
            }`,
          }}
        >
          {!!replacement.selected ? "Selected" : replacement.statusName}

          {replacement.statusId !== 1 && (
            <small>
              <br /> {moment(replacement.statusOn).format("lll")}
            </small>
          )}
        </td>

        <td className="text-right">
          {/* {replacement.selected && (
            <Tooltip title="Selected" className="mr-2">
              <VerifiedIcon className="text-success" />
            </Tooltip>
          )} */}

          <IconButton size="small" onClick={handleOpenMenu}>
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setShowPreviewProfileDialog(true);
                }}
                className="text-primary font-weight-bolder"
              >
                <span className="material-icons mr-1">preview</span>
                Preview
              </MenuItem>
              <Divider className="my-1" />
            </span>

            {replacement.statusId === 2 &&
              !!replacement.selected &&
              ["REB", "RTB"].includes(user.selectedEntity.role) && (
                <span>
                  <MenuItem
                    onClick={(e) => {
                      handleCloseMenu();
                      setShowUnselect(true);
                    }}
                    className="text-warning"
                  >
                    <span className="material-icons mr-1">clear</span>
                    Unselect
                  </MenuItem>
                </span>
              )}

            {((replacement.createdById === user.id &&
              replacement.statusId === 1) ||
              ["REB", "RTB"].includes(user.selectedEntity.role)) && (
              <span>
                <MenuItem
                  onClick={(e) => {
                    handleCloseMenu();
                    setShowConfirmRemove(true);
                  }}
                  className="text-danger"
                >
                  <span className="material-icons mr-1">delete_forever</span>
                  Remove
                </MenuItem>
              </span>
            )}
          </Menu>
        </td>
      </tr>

      {showPreviewProfileDialog && (
        <PreviewReplacementStaffDialog
          showDialog={showPreviewProfileDialog}
          setShowDialog={setShowPreviewProfileDialog}
          replacement={replacement}
          closePalentDialog={() => {}}
        />
      )}

      {showStaffPositionsDialog && (
        <StaffPositionsDialog
          showDialog={showStaffPositionsDialog}
          setShowDialog={setShowStaffPositionsDialog}
          replacement={replacement}
          user={user}
        />
      )}

      {showConfirmRemove && (
        <ConfirmationDialog
          confirmationDialog={showConfirmRemove}
          message={
            <p className="mt-2 mb-0">
              Are you sure you want to{" "}
              <strong className="text-danger">remove</strong> this staff?
            </p>
          }
          setConfirmationDialog={setShowConfirmRemove}
          onYes={() => {
            deleteReplacementStaff(replacement.id, () =>
              setShowConfirmRemove(false)
            );
          }}
        />
      )}

      {showUnselect && (
        <ConfirmationDialog
          confirmationDialog={showUnselect}
          message={
            <>
              <p className="mt-2 mb-0">
                Are you sure you want to{" "}
                <strong className={`text-danger`}>Unselect</strong> this staff?
              </p>
            </>
          }
          setConfirmationDialog={setShowUnselect}
          onYes={() => {
            unselectReplacementStaff(replacement.id, () => {
              setShowUnselect(false);
              // setShowDialog(false);
            });
          }}
        />
      )}
    </>
  );
};

const StaffPositionsDialog = (props) => {
  const { showDialog, setShowDialog, replacement, user } = props;

  const [loading, setLoading] = useState(true);

  const [positions, setPositions] = useState([]);

  const [positionToDelete, setPositionToDelete] = useState(null);

  const dispatch = useDispatch();

  const getPositions = async () => {
    try {
      setLoading(true);

      const { data } = await axios.get(
        `/api/maternity-leave/staff-replacement-positions/${replacement.id}`
      );

      setPositions(data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const removePosition = async (id) => {
    try {
      setLoading(true);

      const { data } = await axios.delete(
        `/api/maternity-leave/staff-replacement-positions/${id}`
      );

      setLoading(false);

      setPositions(data.positions);

      dispatch({
        type: "ADD_OR_UPDATE_MATERNITY_LEAVE_REPLACEMENT_STAFF",
        data: data.staff,
      });

      setPositionToDelete(null);
    } catch (error) {
      setLoading(false);
    }
  };

  const onClose = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    getPositions();
  }, [replacement]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={showDialog}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className="text-primary pb-0 mb-0 text-uppercase border">
        <span className="d-flex align-items-center mb-2">
          <Typography className="d-flex align-items-center text-primary">
            <span className="text-dark mr-1">
              <strong>({replacement.numberOfPositions}) Positions</strong>
            </span>
          </Typography>
        </span>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>

      <DialogContent className="px-0">
        <div className="px-4 pt-2">
          <div className="border rounded">
            <div className="px-3">
              {!!positions.length && (
                <table className="table table-sm table-striped table-hover">
                  <thead>
                    <tr style={{ backgroundColor: "#f0f2f5" }}>
                      <th scope="col">#</th>
                      <th scope="col" className="text-left">
                        Position
                      </th>
                      <th scope="col" className="text-right">
                        Qualification
                      </th>

                      <th scope="col" className="text-center"></th>
                    </tr>
                  </thead>

                  <tbody>
                    {positions.map((position, index) => (
                      <tr>
                        <th scope="row" className="text-left">
                          {index + 1}
                        </th>

                        <td className="text-left">{position.name}</td>

                        <td className="text-right">
                          {position.qualificationLevel}
                        </td>

                        <td className="text-right">
                          {["REB", "RTB"].includes(
                            user.selectedEntity.role
                          ) && (
                            <IconButton
                              color="error"
                              disabled={positions.length <= 1}
                              size="small"
                              onClick={() => setPositionToDelete(position)}
                            >
                              <span className="material-icons">
                                delete_forever
                              </span>
                            </IconButton>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {!positions?.length && (
                <div className="jumbotron jumbotron-fluid text-center">
                  <div className="container">
                    <p className="lead">
                      {loading ? "Fetching data..." : "No record found"}{" "}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          {!!positionToDelete && (
            <ConfirmationDialog
              disabled={loading}
              confirmationDialog={!!positionToDelete}
              message={
                <>
                  <p className="mt-2 mb-0">
                    Are you sure you want to{" "}
                    <strong className={`text-danger`}>repmove</strong> this
                    position?
                  </p>
                </>
              }
              setConfirmationDialog={(e) => setPositionToDelete(null)}
              onYes={() => {
                removePosition(positionToDelete.id);
              }}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
