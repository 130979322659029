import {
  IconButton,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  Box,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
  TextField,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Menu,
  MenuItem,
  ListItem,
  ListItemAvatar,
  Checkbox,
  ListItemText,
  Fab,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import axios from "axios";
import moment from "moment";
import defaultPofile from "../../../assets/default-profile.jpg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import MaskedInput from "react-text-mask";
import joi from "joi";
import { showError } from "../../../toastify";

import { uploadMaternityLeaveAttachments } from "../../../../store/maternity-leave/actions";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const MaternityLeaveAttachmentDialog = (props) => {
  const {
    user,
    loading,
    onWait,
    uploadMaternityLeaveAttachments,
    showDialog,
    setShowDialog,
    request,
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  const [documents, setDocuments] = useState([
    {
      id: 1,
      name: "Birth Certificate",
      file: null,
    },
  ]);

  const onClose = () => {
    setShowDialog(false);
  };

  const [errors, setErrors] = useState([]);

  const attachmentsValidator = () => {
    const error = {
      attachmenErrors: [],
      hasError: false,
    };

    documents.forEach((doc) => {
      const document = documents.find(({ id }) => doc.id === id);

      const documentError = {
        id: doc.id,
        nameHasError: false,
        fileHasError: false,
        message: null,
      };
      if (!document.name) {
        documentError.nameHasError = true;
        error.hasError = true;
      }
      if (!document.file) {
        documentError.fileHasError = true;
        documentError.message = "This field is required";
        error.hasError = true;
      }

      if (!!document.file) {
        if (convertToLower(document.file?.name?.substr(-4)) !== ".pdf") {
          documentError.message = "Only PDF is allowed";
          documentError.fileHasError = true;
          error.hasError = true;
        }

        if (isFileTooLarge(document.file)) {
          documentError.message = "Only 1MB is allowed";
          documentError.fileHasError = true;
          error.hasError = true;
        }
      }

      error.attachmenErrors.push(documentError);
    });

    setErrors(error.attachmenErrors);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const [showUploadedDocument, setShowUploadedDocument] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);

    event.stopPropagation();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const convertToLower = (value) => {
    return value.toLowerCase();
  };

  const isFileTooLarge = (file) => {
    const size = file.size;
    return size > 1074176 ? true : false;
  };

  const handleUploadedPDFFile = (event, document) => {
    let pdfAttachment = event.target.files[0];

    const index = documents?.findIndex(({ id }) => id === document.id);

    const tmpDocuments = [...documents];

    document.file = pdfAttachment;
    document.filename = pdfAttachment.name;

    const reader = new FileReader();

    reader.onload = (e) => {
      document.url = e.target.result;
    };

    reader.readAsDataURL(pdfAttachment);

    if (index < 0) tmpDocuments[index].file = document;

    setErrors([]);
  };

  const onSave = () => {
    if (!attachmentsValidator()) {
      const payload = new FormData();

      payload.append("maternityLeaveId", request.id);
      const descriptions = [];
      documents.forEach((document) => {
        descriptions.push(document.name);
        payload.append("files", document.file);
      });
      payload.append("descriptions", JSON.stringify(descriptions));

      uploadMaternityLeaveAttachments(payload, onClose);
    }
  };

  const [attachments, setAttachments] = useState([]);

  const getMaternityLeaveAttachments = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/maternity-leave/attachments/${request.id}`
      );

      setAttachments(data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMaternityLeaveAttachments();
  }, [request]);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary pb-0 mb-0 text-uppercase border">
          <span className="d-flex align-items-center mb-2">
            <Typography className="d-flex align-items-center text-primary">
              <span className="text-dark mr-1">Supporting documents</span>
            </Typography>
          </span>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="py-0">
          <div
            className="border rounded bg-light mt-3"
            style={{
              minHeight: "30vh",
              padding: "10px",
            }}
          >
            {!request.attachments ? (
              <div className="row no-gutters mt-3">
                {documents.map((doc) => (
                  <div className="row no-gutters col-12 mb-2" key={doc.id}>
                    <div className="col-5 pr-2">
                      <TextField
                        error={
                          !!errors.find(
                            (error) =>
                              error.id === doc.id && !!error.nameHasError
                          )
                        }
                        InputProps={{ readOnly: doc.id === 1 }}
                        style={{
                          backgroundColor: doc.id === 1 ? "" : "#fff",
                        }}
                        fullWidth
                        size="small"
                        name={`description-${doc.id}`}
                        label={`Attachment (${doc.id})`}
                        placeholder={`Description`}
                        variant="outlined"
                        autoFocus
                        value={doc.name}
                        onChange={(e) => {
                          const tmpDocuments = [...documents];
                          const index = tmpDocuments?.findIndex(
                            ({ id }) => id === doc.id
                          );

                          tmpDocuments[index].name = e.target.value;

                          setDocuments(tmpDocuments);

                          setErrors([]);
                        }}
                      />
                      {errors.find(
                        (error) => error.id === doc.id && !!error.nameHasError
                      ) && (
                        <small className="text-danger mt-1">
                          This field is required
                        </small>
                      )}
                    </div>

                    <div className=" col-6">
                      <div className="form-group1 mb-2">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fas fa-paperclip"></i>
                            </span>
                          </div>
                          <input
                            type="file"
                            name="file"
                            className={`form-control ${
                              !!errors.find(
                                (error) =>
                                  error.id === doc.id && !!error.fileHasError
                              )
                                ? "border border-danger text-danger"
                                : ""
                            } `}
                            accept="application/pdf"
                            placeholder="Select pdf file"
                            onChange={(e) => handleUploadedPDFFile(e, doc)}
                          />

                          {/* {!!doc?.file && !!doc?.file?.filename ? (
                          <div
                            className="form-control"
                            style={{
                              backgroundColor: "rgb(229, 246, 253)",

                              color: "#007bff",
                            }}
                          >
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowUploadedDocument(true);
                                setUploadedDocument({
                                  name: doc.name,
                                  url: doc?.file?.url,
                                });
                              }}
                            >
                              {doc?.file?.filename}
                            </span>

                            <IconButton
                              size="small"
                              onClick={handleOpenMenu}
                              style={{
                                marginTop: "-5px",
                                marginRight: "-10px",
                              }}
                              color="info"
                              className="float-right"
                              aria-label="view"
                            >
                              <MoreHorizIcon />
                            </IconButton>

                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={open}
                              onClose={(e) => {
                                handleCloseMenu();
                                e.stopPropagation();
                              }}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  overflow: "visible",
                                  filter:
                                    "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                  mt: 1.5,
                                  "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 2,
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 15,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                            >
                              <span>
                                <MenuItem
                                  onClick={(e) => {
                                    handleCloseMenu();
                                    setShowUploadedDocument(true);
                                    setUploadedDocument({
                                      name: doc.name,
                                      url: doc?.file?.url,
                                    });
                                  }}
                                  className="text-primary font-weight-bolder"
                                >
                                  <span className="material-icons  mr-1">
                                    open_in_new
                                  </span>
                                  Preview
                                </MenuItem>
                              </span>

                              <span>
                                <MenuItem
                                  onClick={(e) => {
                                    handleCloseMenu();

                                    const tmpDocuments = [...documents];
                                    const index = tmpDocuments?.findIndex(
                                      ({ id }) => id === doc.id
                                    );

                                    tmpDocuments[index].file = null;

                                    setDocuments(tmpDocuments);

                                    // const tmpDocuments = [
                                    //   ...teacherForm.attachments,
                                    // ];
                                    // const index = tmpDocuments?.findIndex(
                                    //   ({ id }) => id === doc.id
                                    // );

                                    // tmpDocuments.splice(index, 1);
                                    // setTeacherForm({
                                    //   ...teacherForm,
                                    //   attachments: tmpDocuments,
                                    // });
                                  }}
                                  className="text-danger"
                                >
                                  <span className="material-icons mr-1">
                                    remove_circle_outline
                                  </span>
                                  Remove
                                </MenuItem>
                              </span>
                            </Menu>
                          </div>
                        ) : (
                          <>
                            <input
                              type="file"
                              name="file"
                              className="form-control"
                              accept="application/pdf"
                              placeholder="Select pdf file"
                              onChange={(e) => handleUploadedPDFFile(e, doc)}
                            />
                          </>
                        )} */}
                        </div>
                      </div>
                      {!!errors.find(
                        (error) => error.id === doc.id && !!error.message
                      ) && (
                        <div className="text-right">
                          <small className="text-danger mt-1">
                            {
                              errors.find(
                                (error) =>
                                  error.id === doc.id && !!error.message
                              ).message
                            }
                          </small>
                        </div>
                      )}
                    </div>
                    <div className="col-1 text-center">
                      {doc.id > 1 && (
                        <IconButton
                          disabled={doc.id === 1}
                          color="error"
                          variant="outlined"
                          size="sm"
                          onClick={() => {
                            const tmpDocuments = [...documents];

                            const index = tmpDocuments?.findIndex(
                              ({ id }) => id === doc.id
                            );

                            tmpDocuments.splice(index, 1);

                            tmpDocuments.forEach((d, i) => (d.id = i + 1));

                            setDocuments(tmpDocuments);
                          }}
                        >
                          <span className="material-icons">delete_forever</span>
                        </IconButton>
                      )}
                      {doc.id === 1 && (
                        <IconButton
                          className="ml-1"
                          size="small"
                          color="primary"
                          aria-label="add"
                          disabled={documents.length >= 3}
                          onClick={() => {
                            const tmpDocuments = [...documents];

                            tmpDocuments.push({
                              id: tmpDocuments.length + 1,
                              name: "",
                              file: null,
                            });

                            setDocuments(tmpDocuments);

                            setErrors([]);
                          }}
                        >
                          <span className="material-icons">add</span>
                        </IconButton>
                      )}
                    </div>
                    {/* {doc.id === 1 && (
                      <div className="col-1">
                        <Fab
                          size="small"
                          color="primary"
                          aria-label="add"
                          disabled={documents.length >= 3}
                          onClick={() => {
                            const tmpDocuments = [...documents];

                            tmpDocuments.push({
                              id: tmpDocuments.length + 1,
                              name: "",
                              file: null,
                            });

                            setDocuments(tmpDocuments);
                          }}
                        >
                          <span className="material-icons">add</span>
                        </Fab>
                      </div>
                    )} */}
                  </div>
                ))}
                {/* <div className="row no-gutters col-12">
                  <div className="col-11"></div>
                  <div className="col-1">
                    <Fab
                      size="small"
                      color="primary"
                      aria-label="add"
                      disabled={documents.length >= 3}
                      onClick={() => {
                        const tmpDocuments = [...documents];

                        tmpDocuments.push({
                          id: tmpDocuments.length + 1,
                          name: "",
                          file: null,
                        });

                        setDocuments(tmpDocuments);
                      }}
                    >
                      <span className="material-icons">add</span>
                    </Fab>
                  
                  </div>
                </div> */}
              </div>
            ) : (
              <div className="row mt-3">
                {attachments.map((attachment, index) => (
                  <div
                    className="row no-gutters col-12 mb-2"
                    key={attachment.id}
                  >
                    <div className="col-12 pr-2">
                      <div
                        className="form-control"
                        style={{
                          backgroundColor: "rgb(229, 246, 253)",
                          color: "#007bff",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setUploadedDocument({
                            name: attachment.description,
                            url: "\\" + attachment.filePath,
                          });
                          setShowUploadedDocument(true);
                        }}
                      >
                        <span className="d-inline-flex align-items-center">
                          <span className="material-icons">attach_file</span>
                          {index + 1}: {attachment.description}
                        </span>

                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setUploadedDocument({
                              name: attachment.description,
                              url: "\\" + attachment.filePath,
                            });
                            setShowUploadedDocument(true);
                          }}
                          style={{
                            marginTop: "-5px",
                            marginRight: "-10px",
                          }}
                          color="info"
                          className="float-right"
                          aria-label="view"
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions className=" d-flex justify-content-center py-4">
          {!request.attachments && (
            <Button
              disabled={loading}
              color="primary"
              variant="contained"
              size="sm"
              className="px-5"
              onClick={onSave}
            >
              {loading ? "Wait..." : "Save"}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {showUploadedDocument && uploadedDocument && (
        <PreviewPdfUrl
          isBase64={true}
          showDocument={showUploadedDocument}
          setShowDocument={setShowUploadedDocument}
          document={{
            name: `${uploadedDocument?.name || ""}`,
            teacherName: `${request?.lastName || ""} ${
              request?.firstName || ""
            }`,
            url: uploadedDocument?.url || "",
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading, onWait }) => {
  return {
    user,
    loading,
    onWait,
  };
};
export default connect(mapStateToProps, {
  uploadMaternityLeaveAttachments,
})(MaternityLeaveAttachmentDialog);
