import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import defaultProfile from "../../../assets/default-profile.jpg";
import logoSmall from "../../../assets/REB_Logo.png";

import {
  Alert,
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import { connect } from "react-redux";
import {
  logout,
  selectAcademicYear,
  selectScope,
  selectUserEntity,
} from "../../../../store/common/actions";
import { getSectors } from "../../../../store/posts/actions";
import { isEmpty } from "lodash";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import SupportStaff from "../../../home/pages/SupportStaff";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const ITEM_HEIGHT = 100;

const Header = (props) => {
  const history = useHistory();
  const location = useLocation();
  const {
    lgDrawerState,
    smDrawerState,
    setLGDrawerState,
    setSMDrawerState,

    user,
    logout,
    env,
    loading,
    academicYears,
    selectAcademicYear,

    userEntities,
    selectUserEntity,
    getSectors,
    scopes,
    selectScope,
  } = props;

  const [anchorElEntity, setAnchorElEntity] = useState(null);
  const entityOpen = Boolean(anchorElEntity);
  const handleOpenEntityMenu = (event) => {
    setAnchorElEntity(event.currentTarget);
  };
  const handleCloseEntityMenu = () => {
    setAnchorElEntity(null);
  };

  const [anchorElAcYear, setAnchorElAcYear] = useState(null);
  const acYearOpen = Boolean(anchorElAcYear);
  const handleOpenAcYearMenu = (event) => {
    setAnchorElAcYear(event.currentTarget);
  };
  const handleCloseAcYearMenu = () => {
    setAnchorElAcYear(null);
  };

  const [anchorElScope, setAnchorElScope] = useState(null);
  const scopeOpen = Boolean(anchorElScope);
  const handleOpenScopeMenu = (event) => {
    setAnchorElScope(event.currentTarget);
  };
  const handleCloseScopeMenu = () => {
    setAnchorElScope(null);
  };

  const [anchorElUser, setAnchorElUser] = useState(null);
  const userMenuOpen = Boolean(anchorElUser);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [showSupportStaffDialog, setShowSupportStaffDialog] = useState(false);

  return (
    <>
      <Box>
        <AppBar position="static" elevation={0} className="app-bar">
          <Toolbar className="pr-2 tool-bar d-flex align-items-center">
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              className="mr-2 d-inline d-lg-none"
              onClick={() => {
                setSMDrawerState(!smDrawerState);
                setLGDrawerState(true);
              }}
            >
              <MenuIcon
                style={{
                  color: "#015198",
                  fontWeight: "bolder",
                  fontSize: "34px",
                }}
              />
            </IconButton>
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              className="mr-2 d-none d-lg-inline"
              onClick={() => {
                setLGDrawerState(!lgDrawerState);
                setSMDrawerState(false);
              }}
            >
              <MenuIcon
                style={{
                  color: "#015198",
                  fontWeight: "bolder",
                  fontSize: "34px",
                }}
              />
            </IconButton>
            {/* )} */}
            <Typography component="div">
              <strong className="">
                <span
                  style={{
                    color: "#ed6c02",
                    fontWeight: "bolder",
                    fontSize: "22px",
                  }}
                >
                  TMIS
                </span>
              </strong>
            </Typography>
            <Box sx={{ flexGrow: 1 }} />

            <Button
              onClick={() => setShowSupportStaffDialog(true)}
              style={{ backgroundColor: "#dbeafe" }}
              className="p-0 support-staff d-none d-lg-inline-flex"
            >
              {" "}
              <span
                style={{ backgroundColor: "#bfdbfe", borderRadius: "5px" }}
                className="d-inline-flex mr-2 py-2 px-2 ico"
              >
                <PermPhoneMsgIcon />
              </span>{" "}
              <span>Support Staff </span>
              <span
                className="bg-white d-inline-flex  mx-2 p-1 ico"
                style={{ borderRadius: "50%" }}
              >
                <ArrowForwardIcon />
              </span>
            </Button>

            <Box sx={{ flexGrow: 1 }} />

            <div className="roles text-primary  d-flex">
              <>
                <strong className="d-flex">
                  <span
                    style={{
                      color: "#ed6c02",
                      fontWeight: "bolder",
                      fontSize: "16px",
                    }}
                  >
                    {!!user?.selectedEntity?.isActingTeacher && <>ACTING_</>}
                    {user?.selectedEntity?.role} [{user.id}]
                  </span>
                  <DragIndicatorIcon style={{ color: "#cecece" }} />
                </strong>
              </>
            </div>

            <span className="mr-1 badge badge-secondary d-none d-md-inline">
              {user?.lastName}
            </span>
            <IconButton onClick={handleOpenUserMenu} className="p-0 ml-1">
              {!user.profileImage && (
                <Avatar
                  alt="Profile picture"
                  style={{ border: "1px solid #a7a7a7" }}
                  src={defaultProfile}
                />
              )}
              {!!user.profileImage && (
                <Avatar
                  style={{ border: "1px solid #c3c4c6" }}
                  alt="Profile picture"
                  src={"data:image/jpg;base64," + user.profileImage}
                />
              )}
            </IconButton>
            <Menu
              anchorEl={anchorElUser}
              id="account-menu"
              open={userMenuOpen}
              onClose={handleCloseUserMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem disabled className="text-uppercase">
                {user?.lastName} {user?.firstName}
              </MenuItem>
              <Divider />

              <MenuItem
                onClick={() => {
                  handleCloseUserMenu();
                  history.push("/in/security/my-profile");
                }}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu();
                  history.push("/in/security/change-password");
                }}
              >
                <ListItemIcon>
                  <LockIcon />
                </ListItemIcon>
                Change Password
              </MenuItem>
              <Divider />

              <MenuItem
                className="text-danger"
                onClick={() => {
                  handleCloseUserMenu();
                  logout(history);
                }}
              >
                <ListItemIcon className="text-danger">
                  <LogoutIcon />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>

        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            className="app-bar w-100"
            style={{
              borderBottom: "1px solid #dee2e6",
            }}
          >
            <Toolbar>
              <Box className="mr-2">
                <Tooltip title={user.selectedScope.name}>
                  <Button
                    variant="text"
                    disableRipple
                    onClick={handleOpenScopeMenu}
                    endIcon={<KeyboardArrowDownIcon className="text-primary" />}
                    style={{
                      backgroundColor: "rgb(219, 234, 254)",
                      color: "#1976d2",
                      fontWeight: "bold",
                      // width: "190px",
                    }}
                  >
                    <span
                      className={` mr-1 `}
                      style={{
                        fontFamily:
                          '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                        fontSize: "1rem",
                        // fontWeight: "400",
                        lineHeight: "1.5",
                      }}
                    >
                      <span className=" ">{user.selectedScope.name} </span>
                    </span>
                  </Button>
                </Tooltip>

                <Menu
                  id="years-menu"
                  anchorEl={anchorElScope}
                  open={scopeOpen}
                  onClose={handleCloseScopeMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {/* <MenuItem disabled className="text-uppercase">
                    Ypur scope
                  </MenuItem>
                  <Divider /> */}
                  {scopes.map((scope, index) => (
                    <span key={scope.id}>
                      <MenuItem
                        className="py-0 my-0 text-right"
                        disabled={loading}
                        onClick={() => {
                          if (scope.id === user?.selectedScope?.id)
                            handleCloseScopeMenu();
                          else selectScope(scope, handleCloseScopeMenu);
                        }}
                      >
                        <span
                          className={` ${
                            scope.id === user?.selectedScope?.id
                              ? "font-weight-bold"
                              : "font-weight-light"
                          }`}
                        >
                          <span className="">{scope.name}</span>
                        </span>
                      </MenuItem>

                      {index < scopes.length - 1 && <Divider />}
                    </span>
                  ))}
                </Menu>
              </Box>

              <Box className="">
                <Tooltip title="Academic Year">
                  <Button
                    variant="text"
                    disableRipple
                    onClick={handleOpenAcYearMenu}
                    endIcon={<KeyboardArrowDownIcon className="text-primary" />}
                    style={{
                      backgroundColor: "#f3f4f6",
                      width: "190px",
                    }}
                  >
                    <span
                      className={`text-dark mr-1 `}
                      style={{
                        fontFamily:
                          '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                        fontSize: "1rem",
                        fontWeight: "400",
                        lineHeight: "1.5",
                      }}
                    >
                      <span className="text-dark ">
                        {user.selectedAcademicYear.name}{" "}
                      </span>
                    </span>
                    {user.selectedAcademicYear.active && (
                      <span
                        className=" font-weight-bolder"
                        style={{ color: "#00b028" }}
                      >
                        (Active)
                      </span>
                    )}

                    {!user.selectedAcademicYear.active && (
                      <span
                        className="font-weight-bold"
                        style={{ color: "red" }}
                      >
                        (Closed)
                      </span>
                    )}
                  </Button>
                </Tooltip>

                <Menu
                  id="years-menu"
                  anchorEl={anchorElAcYear}
                  open={acYearOpen}
                  onClose={handleCloseAcYearMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem disabled className="text-uppercase">
                    Academic Years
                  </MenuItem>
                  <Divider />
                  {academicYears.map((academicYear, index) => (
                    <span key={academicYear.id}>
                      <MenuItem
                        className="py-0 my-0 text-right"
                        disabled={loading}
                        onClick={() => {
                          if (
                            academicYear.id === user?.selectedAcademicYear?.id
                          )
                            handleCloseAcYearMenu();
                          else
                            selectAcademicYear(
                              academicYear,
                              handleCloseAcYearMenu
                            );
                        }}
                      >
                        <span
                          className={` ${
                            academicYear.id === user?.selectedAcademicYear?.id
                              ? "font-weight-bold"
                              : "font-weight-light"
                          }`}
                        >
                          <span className="">{academicYear.name}</span>

                          {academicYear.active && (
                            <span
                              className="ml-1 text-uppercase"
                              style={{ color: "green", fontSize: "10px" }}
                            >
                              Active
                            </span>
                          )}

                          {!academicYear.active && (
                            <span
                              className="ml-1 text-uppercase"
                              style={{ color: "red", fontSize: "10px" }}
                            >
                              Closed
                            </span>
                          )}
                        </span>
                      </MenuItem>

                      {index < academicYears.length - 1 && <Divider />}
                    </span>
                  ))}
                </Menu>
              </Box>

              {/* <Box sx={{ flexGrow: 1 }} /> */}
              {/* 
              <Alert severity="error">
                This is an error alert — check it out! This is an error alert —
                check it out!
              </Alert> */}

              <Box sx={{ flexGrow: 1 }} />

              {/* <Button
                onClick={() => {}}
                variant="contained"
                size="small"
                disableElevation
                className="mr-2"
                color="primary"
                style={{
                  borderRadius: "8px",
                  height: "28px",
                  alignItems: "center",
                  fontSize: ".75rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textTransform: "initial",
                  backgroundColor: "#63a1f4",
                }}
              >
                <span className="material-icons">search</span>
                Find Staff
              </Button> */}

              {userEntities.length > 1 && (
                <Typography variant="h6" noWrap component="div">
                  <Button
                    variant="text"
                    onClick={handleOpenEntityMenu}
                    endIcon={<KeyboardArrowDownIcon className="text-primary" />}
                    style={{
                      backgroundColor: "#f3f4f6",
                      borderRadius: "5px",
                    }}
                    className="px-2 text-uppercase text-primary d-flex align-items-center border"
                  >
                    <span
                      style={{
                        backgroundColor: "#f3f4f6",
                        borderRadius: "5px",
                      }}
                      className="px-2 text-uppercase text-primary d-flex align-items-center "
                    >
                      <i className="fas fa-school mr-2 mt-n1"></i>{" "}
                      <span>
                        {user?.selectedEntity?.name}{" "}
                        {user?.selectedEntity?.type}
                        {user?.selectedEntity?.id === "00000000" && <>S</>}
                      </span>
                    </span>
                  </Button>
                  <Menu
                    id="entity-menu"
                    anchorEl={anchorElEntity}
                    open={entityOpen}
                    onClose={handleCloseEntityMenu}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "auto",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        maxHeight: ITEM_HEIGHT * 4.5,
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    {userEntities.map((entity, index) => (
                      <span key={entity.id}>
                        <MenuItem
                          className="py-0 my-0"
                          disabled={loading}
                          onClick={() => {
                            if (entity.id === user?.selectedEntity?.id)
                              handleCloseEntityMenu();
                            else
                              selectUserEntity(entity, handleCloseEntityMenu);
                          }}
                        >
                          <span
                            className={`text-uppercase ${
                              entity.id === user?.selectedEntity?.id
                                ? "font-weight-bold"
                                : "font-weight-light"
                            }`}
                          >
                            {entity.name}
                          </span>
                        </MenuItem>

                        {index < userEntities.length - 1 && <Divider />}
                      </span>
                    ))}
                  </Menu>
                </Typography>
              )}

              {!(userEntities.length > 1) && (
                <Typography variant="h6" noWrap component="div">
                  <span
                    style={{
                      backgroundColor: "#f3f4f6",
                      borderRadius: "5px",
                      fontSize: "18px",
                    }}
                    className="px-2 text-uppercase text-primary d-flex align-items-center "
                  >
                    <i className="fas fa-school mr-2 mt-n1"></i>{" "}
                    <span>
                      {user?.selectedEntity?.name} {user?.selectedEntity?.type}
                    </span>
                  </span>
                </Typography>
              )}
            </Toolbar>
          </AppBar>
        </div>
      </Box>

      {showSupportStaffDialog && (
        <SupportStaff
          showSupportStaffDialog={showSupportStaffDialog}
          setShowSupportStaffDialog={setShowSupportStaffDialog}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  env,
  academicYears,
  userEntities,
  scopes,
}) => {
  return {
    user,
    loading,
    env,
    academicYears,
    userEntities,
    scopes,
  };
};
export default connect(mapStateToProps, {
  logout,
  selectAcademicYear,
  getSectors,
  selectUserEntity,
  selectScope,
})(Header);
