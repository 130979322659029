import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  IconButton,
  Badge,
  Divider,
  TextField,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { connect } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  getArchivedMaternityLeaves,
  deleteMaternityLeaveRequest,
} from "../../../../store/maternity-leave/actions";
import ReactPaginate from "react-paginate";
import moment from "moment";
import AddOrUpdateMaternityLeaveDialog from "../components/AddOrUpdateMaternityLeaveDialog";
import AddOrUpdateReplacementStaffDialog from "../components/AddOrUpdateReplacementStaffDialog";
import AlertDialog from "../../common/components/AlertDialog";
import PreviewReplacementStaffDialog from "../components/PreviewReplacementStaffDialog";
import PreviewTeacherProfile from "../components/PreviewTeacherProfile";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { CSVLink } from "react-csv";
import MaternityLeaveAttachmentDialog from "../components/MaternityLeaveAttachmentDialog";

const Archived = (props) => {
  useEffect(() => {
    document.title = "TMIS | Maternity Leave - In Progress";
  }, []);

  const {
    user,
    loading,
    archivedMaternityLeaves,
    getArchivedMaternityLeaves,
    deleteMaternityLeaveRequest,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecods, setTotalRecods] = useState(0);
  const [loader, setLoader] = useState(false);
  const [filterStatus, setFilterStatus] = useState({
    id: "",
    name: "All",
  });

  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );

  useEffect(() => {
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
      statusId: filterStatus.id,
    };
    getArchivedMaternityLeaves(query, setTotalRecods, setLoader);
  }, [user.selectedEntity, user.selectedAcademicYear, filterStatus]);

  const onSearch = (term) => {
    setSearchTerm(term);

    if (term === "") {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        searchTerm: _searchTerm,
        page: _currentPage,
        size: itemPerPage,
        statusId: filterStatus.id,
      };
      getArchivedMaternityLeaves(query, setTotalRecods, setLoader);
    }
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);

    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
      statusId: filterStatus.id,
    };

    getArchivedMaternityLeaves(query, setTotalRecods, setLoader);
  };

  return (
    <>
      <div className="px-4 pt-2">
        <div className="border rounded">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar
              style={{
                minHeight: "0px",
                borderBottom: "3px solid #e9ecef",
              }}
              className=" my-0 py-1 bg-light"
            >
              <Typography
                variant="p"
                noWrap
                component="div"
                className="d-flex  ilign-items-center text-uppercase text-primary font-weight-bold"
              >
                <span className="text-dark">
                  ({totalRecods}) Archived Requests
                </span>
                <CSVLink
                  data={archivedMaternityLeaves}
                  filename={`MaternityLeaves - ${filterStatus?.name}.csv`}
                  className="hidden"
                  id="downloadBTN"
                  target="_blank"
                />

                <Tooltip title="Download">
                  <span className="ml-2">
                    <IconButton
                      size="small"
                      disabled={loading || !archivedMaternityLeaves.length}
                      className=" mr-1"
                      color="primary"
                      onClick={() =>
                        document.getElementById("downloadBTN").click()
                      }
                    >
                      <span className="material-icons">file_download</span>
                    </IconButton>
                  </span>
                </Tooltip>
              </Typography>
              <Box sx={{ flexGrow: 1 }} />

              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <div>
                  <Autocomplete
                    style={{
                      width: "200px",
                    }}
                    className="mr-2"
                    size="small"
                    defaultValue={null}
                    value={filterStatus}
                    options={[
                      { id: "", name: "All" },
                      { id: 1, name: "Pending for approval" },
                      { id: 2, name: "Approved" },
                      { id: 4, name: "Replaced" },
                      { id: 3, name: "Rejected" },
                    ]}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="bank"
                        label="Filter By"
                        placeholder="Filter By"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                    onChange={(event, status) => {
                      setFilterStatus(status || { id: "", name: "All" });
                    }}
                  />
                </div>
                <SearchBox
                  onSearch={onSearch}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      const query = {
                        searchTerm: searchTerm,
                        page: currentPage,
                        size: itemPerPage,
                        statusId: filterStatus.id,
                      };

                      getArchivedMaternityLeaves(
                        query,
                        setTotalRecods,
                        setLoader
                      );
                    }
                  }}
                  placeholder="Search…"
                />
              </Box>
            </Toolbar>
          </AppBar>
          <div
            style={{
              backgroundColor: "#f3f4f6",
              borderRadius: "0.5rem",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
              height: "59vh",
              overflowY: "auto",
            }}
            className="px-3"
          >
            {!!archivedMaternityLeaves.length && (
              <table className="table table-striped table-hover   fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col">#</th>
                    <th scope="col" className="text-left">
                      Teacher Names
                    </th>

                    <th scope="col" className="text-left">
                      From Date
                    </th>

                    <th scope="col" className="text-left">
                      To Date
                    </th>

                    <th scope="col" className="text-left">
                      Requested On
                    </th>

                    <th
                      scope="col"
                      className="text-left"
                      style={{
                        borderLeft: "1px solid #dee2e6",
                        borderRight: "1px solid #dee2e6",
                      }}
                    >
                      Replacing Staff
                    </th>
                    <th scope="col" className="text-right">
                      Status
                    </th>
                    {/* <th scope="col" className="text-left">
                      Status On
                    </th> */}
                  </tr>
                </thead>

                <tbody>
                  {archivedMaternityLeaves.map((request) => (
                    <MaternityLeaveItemCard
                      key={request.id}
                      request={request}
                      user={user}
                      loading={loading}
                      deleteMaternityLeaveRequest={deleteMaternityLeaveRequest}
                    />
                  ))}
                </tbody>
              </table>
            )}

            {!archivedMaternityLeaves?.length && (
              <div className="jumbotron jumbotron-fluid text-center">
                <div className="container py-5 ">
                  <p className="lead">
                    {loading ? "Fetching data..." : "No request made"}{" "}
                  </p>
                </div>
              </div>
            )}
          </div>
          {!!archivedMaternityLeaves.length && (
            <div
              className="d-flex justify-content-center  align-items-center"
              style={{ overflow: "hidden" }}
            >
              <>
                <div className="mt-3">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      <>
                        <span className="pr-1 d-none d-md-inline">Next</span>
                        <i className="fas fa-angle-double-right"></i>
                      </>
                    }
                    previousLabel={
                      <>
                        <i className="fas fa-angle-double-left"></i>
                        <span className="pl-1  d-none d-md-inline">
                          Previous
                        </span>
                      </>
                    }
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    pageCount={Math.ceil(totalRecods / itemPerPage)}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
                    previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
                    nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
                    activeLinkClassName="active"
                  />
                </div>

                <select
                  className="custom-select custom-select-sm mr-3 ml-2"
                  style={{ width: "100px" }}
                  value={itemPerPage}
                  onChange={(event) => {
                    const itemPerPage = +event.target.value;
                    window.localStorage.setItem("ITEM_PER_PAGE", itemPerPage);
                    setItemPerPage(itemPerPage);
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                      statusId: filterStatus.id,
                    };
                    getArchivedMaternityLeaves(
                      query,
                      setTotalRecods,
                      setLoader
                    );
                  }}
                >
                  {[10, 50, 100, 150].map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              </>
            </div>
          )}
        </div>
      </div>

      {loader && (
        <CircularProgress
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "rgb(7, 142, 206)",
            position: "absolute",
            top: "42%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
          size={40}
          thickness={4}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading, archivedMaternityLeaves }) => {
  return { user, loading, archivedMaternityLeaves };
};
export default connect(mapStateToProps, {
  getArchivedMaternityLeaves,
  deleteMaternityLeaveRequest,
})(Archived);

const MaternityLeaveItemCard = (props) => {
  const { loading, user, request, deleteMaternityLeaveRequest } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showPreviewProfileDialog, setShowPreviewProfileDialog] =
    useState(false);

  const [
    showPreviewReplacementStaffDialog,
    setShowPreviewReplacementStaffDialog,
  ] = useState(false);

  const [statusId, setStatusId] = useState(null);
  const [statusComment, setStatusComment] = useState("");
  const [showStatusComment, setShowStatusComment] = useState(false);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [showReplacementStaffFormDialog, setShowReplacementStaffFormDialog] =
    useState(false);

  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);

  return (
    <>
      <tr>
        <th scope="row" className="text-left">
          {request.no}
        </th>

        <td className="text-left">
          <button
            onClick={() => setShowPreviewProfileDialog(true)}
            className="btn btn-link btn-sm px-0 py-0 text-uppercase text-left text-underline font-weight-bold"
          >
            {request.lastName} {request.firstName}{" "}
            {request.requestedBy === user.id && (
              <span className="badge badge-primary">Me</span>
            )}
          </button>
          <div>
            {request.positionName} ({request.qualificationLevelName}) <br />
            <span className="text-primary font-weight-bold">
              @{request.schoolName} <i className="fas fa-chevron-right"></i>{" "}
              {request.sectorName} / {request.districtName}
            </span>
          </div>
        </td>

        <td className="text-left">{moment(request.fromDate).format("ll")}</td>

        <td className="text-left">{moment(request.toDate).format("ll")}</td>

        <td className="text-left">
          {moment(request.requestedOn).format("lll")}
        </td>

        <td
          className="text-left "
          style={{
            backgroundColor: "#f3f4f6",
            borderLeft: "1px solid #dee2e6",
            borderRight: "1px solid #dee2e6",
          }}
        >
          {" "}
          {!!request.actingStaff ? (
            <button
              onClick={() => setShowPreviewReplacementStaffDialog(true)}
              className="btn btn-link btn-sm px-0 text-uppercase text-left  py-0"
            >
              {request.actingStaff}
            </button>
          ) : (
            <span className="text-danger">Not set</span>
          )}
        </td>

        <td
          // className="text-right"
          className={`
                   text-right 
                   ${
                     [2, 3].includes(request.statusId)
                       ? "font-weight-bold text-light"
                       : ""
                   }
                 `}
          style={{
            backgroundColor: `${
              request.statusId === 2 && !request.replacingStaff
                ? "#3b35dc"
                : request.statusId === 2 && !!request.replacingStaff
                ? "#28a745"
                : request.statusId === 3
                ? "#dc3545"
                : "none"
            }`,
          }}
        >
          <span>
            {request.statusId === 3 && (
              <IconButton
                color="info"
                size="small"
                className="mr-1"
                onClick={() => {
                  setShowStatusComment(true);
                }}
              >
                <span className="material-icons">info</span>
              </IconButton>
            )}

            {!!request.replacingStaff ? "Replaced" : request.statusName}

            {request.statusId !== 1 && (
              <small>
                <br /> {moment(request.statusOn).format("lll")}
              </small>
            )}
          </span>
        </td>

        <td className="text-right">
          <span className="d-inline-flex">
            {!!request.attachments ? (
              <Tooltip title="Attachments">
                <span
                  className="badge d-inline-flex align-items-center text-primary btn-link cursor-pointer"
                  style={{ textDecoration: "none", fontSize: "10px" }}
                  onClick={() => {
                    setShowAttachmentDialog(true);
                  }}
                >
                  {request.attachments} Attachment
                  {request.attachments > 1 ? "s" : ""}{" "}
                  <span className="material-icons ml-1 ">insert_link</span>{" "}
                </span>
              </Tooltip>
            ) : request.statusId === 2 && request.requestedBy === user.id ? (
              <Button
                className="mr-2"
                variant="contained"
                color="success"
                size="small"
                style={{
                  borderRadius: "8px",
                  height: "32px",
                  alignItems: "center",
                  fontSize: ".75rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textTransform: "initial",
                }}
                onClick={() => {
                  setShowAttachmentDialog(true);
                }}
              >
                <span className="material-icons mr-1">upload</span>
                Supporting docs
              </Button>
            ) : (
              request.statusId === 2 && (
                <Tooltip title="Attachments">
                  <span
                    className="badge d-inline-flex align-items-center text-danger"
                    style={{ cursor: "default", fontSize: "10px" }}
                  >
                    Not set
                    <span className="material-icons ml-1">link_off</span>
                  </span>
                </Tooltip>
              )
            )}
            <IconButton
              size="small"
              onClick={handleOpenMenu}
              aria-label="settings"
            >
              <Badge color="info">
                <span className="material-icons">more_vert</span>
              </Badge>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 2,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 15,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {["REB", "RTB"].includes(user.selectedEntity.role) && (
                <span>
                  <MenuItem
                    onClick={(e) => {
                      handleCloseMenu();
                      setShowConfirmDelete(true);
                    }}
                    className="text-danger"
                  >
                    <span className="material-icons mr-1">
                      remove_circle_outline
                    </span>
                    Remove
                  </MenuItem>
                </span>
              )}
            </Menu>
          </span>
        </td>
      </tr>

      {showStatusComment && (
        <AlertDialog
          maxWidth="xs"
          showAlert={showStatusComment}
          setShowAlert={setShowStatusComment}
        >
          <TextField
            InputProps={{
              readOnly: true,
            }}
            className="mt-3 bg-light"
            multiline
            rows={5}
            autoFocus
            fullWidth
            type="text"
            size="small"
            name="reason"
            label="Reason for rejection"
            variant="outlined"
            placeholder="Reason for rejection"
            value={request.statusComment}
          />

          <span className="badge badge-pill text-right d-block">
            By:{request.statusBy}
          </span>
        </AlertDialog>
      )}

      {!!showConfirmDelete && (
        <ConfirmationDialog
          confirmationDialog={showConfirmDelete}
          message={
            <>
              <p className="mt-2">
                Are you sure you want to{" "}
                <strong className={`text-danger`}>delete</strong> this request?
              </p>
            </>
          }
          setConfirmationDialog={setShowConfirmDelete}
          onYes={() => {
            deleteMaternityLeaveRequest(request.id, () =>
              setShowConfirmDelete(false)
            );
          }}
        />
      )}

      {showReplacementStaffFormDialog && (
        <PreviewReplacementStaffDialog
          showDialog={showReplacementStaffFormDialog}
          setShowDialog={setShowReplacementStaffFormDialog}
          request={request}
        />
      )}

      {showPreviewProfileDialog && (
        <PreviewTeacherProfile
          showDialog={showPreviewProfileDialog}
          setShowDialog={setShowPreviewProfileDialog}
          teacherId={request.teacherId}
        />
      )}

      {showPreviewReplacementStaffDialog && (
        <PreviewTeacherProfile
          showDialog={showPreviewReplacementStaffDialog}
          setShowDialog={setShowPreviewReplacementStaffDialog}
          teacherId={request.replacingStaffUserId}
        />
      )}

      {showAttachmentDialog && (
        <MaternityLeaveAttachmentDialog
          showDialog={showAttachmentDialog}
          setShowDialog={setShowAttachmentDialog}
          request={request}
        />
      )}
    </>
  );
};
